export const environment = {
  production: false,
  apiBase: 'https://londonhydro-duom-prod.web.app/api/v1',
  firebaseConfig: {    
    apiKey: "AIzaSyCy_uUMgBWeWtAF95aX-67oHcxouSRpVD8",
  	authDomain: "londonhydro-duom-prod.firebaseapp.com",
  	projectId: "londonhydro-duom-prod",
  	storageBucket: "londonhydro-duom-prod.appspot.com",
  	messagingSenderId: "557901800274",
  	appId: "1:557901800274:web:2053ccc5bad4e94670d08b",
  	measurementId: 'G-DMFD2F16BE'
  }
};